globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"f16cc0da43c6ac0a59d6d04514e3b17ae9284e13"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

if (isProduction && SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,
    // exclude the FAQ pages from error tracking, because Kundo is the cause of the errors
    denyUrls: [
      /\/help\/faq/,
      /\/hjalp\/faq/,
      /\/hjelp\/vanliga-sporsmal/,
      /\/hjalp\/ofte-stillede-sporgsmal/,
      /\/hilfe\/faq/,
      /\/aide\/faq/,
      /\/aiuto\/faq/,
      /\/ayuda\/preguntas-frecuentes/,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
} else {
  Sentry.init({
    enabled: false,
  })
}
