/* eslint-disable @next/next/no-img-element */
import { Box, Container, Heading, Text } from 'atomic'
import SocialMedia from 'components/builder_components/social_media/SocialMedia'
import AppStore from 'components/icons/AppStore'
import GooglePlay from 'components/icons/GooglePlay'
import OUTextLogo from 'components/icons/OU_TextLogo'
import { LanguagePicker } from 'components/languagepicker/LanguagePicker'
import { Link } from 'components/link/Link'
import { useBackend } from 'context/BackendContext'
import { FooterData, PageAlternateLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import * as css from './Footer.css'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import Image from 'next/image'
import BCorpImage from 'public/images/bCorp_Logo.png'
import useAppLinks from 'hooks/useAppLinks'
import { trackOutboundLink } from 'lib/analytics-utils'
import { ouTheme } from 'atomic/styles/theme.css'

type FooterProps = {
  pageAlternates?: PageAlternateLink[]
  footerData?: FooterData
}

const defaultLocale = 'en-gb'

const Footer: FC<FooterProps> = ({ pageAlternates, footerData }) => {
  const { paymentOptions } = useBackend()
  const router = useRouter()
  const { locale } = router
  const localeIsNotDefault = locale !== defaultLocale
  const { t } = useSafeTranslation()
  const { appStoreUrl } = useAppLinks()
  const { googlePlayUrl } = useAppLinks()

  // Kundo hack
  const language = router.locale.length > 2 ? router.locale.slice(0, 2) : 'en'
  let url = 'https://once.upon.se.kb.kundo.se/js/kundo-knowledge-widget.js'
  switch (language) {
    case 'en':
      url = 'https://once.upon.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'da':
      url = 'https://upon.once.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'fr':
      url = 'https://onceupon2.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'it':
      url = 'https://oonce.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'nl':
      url = 'https://once.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'nb':
      url = 'https://once.upn.se.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'es':
      url = 'https://es.once.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'sv':
      url = 'https://onceupon1.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    case 'de':
      url = 'https://once.upon.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
    default:
      url = 'https://once.upon.se.kb.kundo.se/js/kundo-knowledge-widget.js'
      break
  }

  const useScript = (url): void => {
    useEffect(() => {
      // When we change language Kundo seems to create a new div container each time.
      // We need to remove it for it to work.
      const kundoWidgetElement = document.getElementById('kundo-knowledge-widget')
      if (kundoWidgetElement !== undefined && kundoWidgetElement !== null) {
        kundoWidgetElement.remove()
      }

      const script = document.createElement('script')

      script.src = url
      script.async = true

      document.body.appendChild(script)

      return () => {
        if (document.body.contains(script)) {
          document.body.removeChild(script)
        }
      }
    }, [url])
  }
  useScript(url)

  return (
    <Box backgroundColor={'kantarell2'} paddingY={'xxl'}>
      <Container
        display={'flex'}
        flexDirection={{ mobile: 'column', desktop: 'row' }}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        gap={{ mobile: 'xl', desktop: 'l' }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={'xl'} style={{ width: '60%' }}>
          <LanguagePicker className={''} pageAlternates={pageAlternates} />
        </Box>

        <Box display={{ mobile: 'none', desktop: 'flex' }} flexDirection={'column'} gap={'s'} style={{ width: '70%' }}>
          <Heading as={'h4'} fontSize={'s'} fontWeight={'extrabold'} marginBottom={'none'}>
            {t('website_footer_option_3')}
          </Heading>
          <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
            {footerData?.data?.informationNavLinks?.map((link, index) => (
              <Link key={index} href={link.externalLink ? link.url : localeIsNotDefault ? `/${locale}${link.url}` : link.url}>
                <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} className={css.links}>
                  {link.label}
                </Text>
              </Link>
            ))}
          </Box>
        </Box>
        <Box display={{ mobile: 'none', desktop: 'flex' }} flexDirection={'column'} gap={'s'} style={{ width: '70%' }}>
          <Heading as={'h4'} fontSize={'s'} fontWeight={'extrabold'} marginBottom={'none'}>
            {t('website_footer_option_4')}
          </Heading>
          <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
            {footerData?.data?.aboutNavLinks?.map((link, index) => (
              <Link key={index} href={link.externalLink ? link.url : localeIsNotDefault ? `/${locale}${link.url}` : link.url}>
                <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} className={css.links}>
                  {link.label}
                </Text>
              </Link>
            ))}
          </Box>
        </Box>
        {/* Need to make a separate Box for mobile to fit the design */}
        <Box display={{ mobile: 'flex', desktop: 'none' }} gap={{ mobile: 'l', desktop: 'l' }} style={{ width: '100%' }}>
          <Box display={'flex'} flexDirection={'column'} gap={'s'} style={{ width: 'fit-content' }}>
            <Heading as={'h4'} fontSize={'s'} fontWeight={'extrabold'} marginBottom={'none'}>
              {t('website_footer_option_3')}
            </Heading>
            <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
              {footerData?.data?.informationNavLinks?.map((link, index) => (
                <Link key={index} href={link.externalLink ? link.url : localeIsNotDefault ? `/${locale}${link.url}` : link.url}>
                  <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} className={css.links}>
                    {link.label}
                  </Text>
                </Link>
              ))}
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'s'} style={{ width: 'fit-content' }}>
            <Heading as={'h4'} fontSize={'s'} fontWeight={'extrabold'} marginBottom={'none'}>
              {t('website_footer_option_4')}
            </Heading>
            <Box display={'flex'} flexDirection={'column'} gap={'xxxs'}>
              {footerData?.data?.aboutNavLinks?.map((link, index) => (
                <Link key={index} href={link.externalLink ? link.url : localeIsNotDefault ? `/${locale}${link.url}` : link.url}>
                  <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} className={css.links}>
                    {link.label}
                  </Text>
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'xl'} style={{ width: '90%' }} marginBottom={{ mobile: 'xl', desktop: 'none' }}>
          <Box display={'flex'} flexDirection={'column'} gap={'s'} style={{ width: '100%' }}>
            <Heading as={'h4'} fontSize={'s'} fontWeight={'extrabold'} marginBottom={'none'}>
              {t('website_footer_option_2')}
            </Heading>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={'xxs'}>
              {paymentOptions?.map((option) => (
                <img src={option.image?.url} loading="lazy" alt={option.name} key={option.name} style={{ maxHeight: 26 }} />
              ))}
              <Image src="/icons/PoweredByStripe_black.svg" alt="Powered by Stripe" height={26} width={114} />
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'s'} style={{ width: '100%' }}>
            <Heading as={'h4'} fontSize={'s'} fontWeight={'extrabold'} marginBottom={'none'}>
              {t('website_footer_option_1')}
            </Heading>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} flexWrap={'wrap'} gap={'xxs'}>
              <Link onClick={() => trackOutboundLink(appStoreUrl)} href={appStoreUrl} target="_blank" aria-label="Link to Appstore">
                <AppStore locale={locale} />
              </Link>
              <Link onClick={() => trackOutboundLink(googlePlayUrl)} href={googlePlayUrl} target="_blank" aria-label="Link to Google Play">
                <GooglePlay locale={locale} />
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={{ mobile: 'flex-start', desktop: 'flex-end' }}
          gap={{ mobile: 'l', desktop: 's' }}
          style={{ width: '100%' }}
        >
          <OUTextLogo width={188} />
          <Image
            src={BCorpImage}
            alt="B Corp certified badge"
            width={48}
            height={70}
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            style={{ width: 48, height: 70 }}
          />
          <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'flex-start', desktop: 'flex-end' }} gap={'m'}>
            <SocialMedia socialLink={footerData?.data?.socialMediaLinks} iconSize={'l'} />
            <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'flex-start', desktop: 'flex-end' }} gap={'none'}>
              <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} textAlign={{ mobile: 'left', desktop: 'right' }}>
                {footerData?.data?.companyInformation?.nameOrgNum}
              </Text>
              <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} textAlign={{ mobile: 'left', desktop: 'right' }}>
                {footerData?.data?.companyInformation?.address}
              </Text>
              <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} textAlign={{ mobile: 'left', desktop: 'right' }}>
                {footerData?.data?.companyInformation?.copyrightEmail}
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
