import { SocialMediaLink } from 'components/builder_components/social_media/SocialMedia'
import { createContext, FC, ReactNode, useContext } from 'react'

export type PageAlternateLink = {
  hreflang: string
  href: string
}

export type NavigationHeader = {
  url: string
  text: string
  hide?: boolean
  campaign?: boolean
  navItems?: NavigationItem[]
}

export type NavigationItem = {
  url: string
  text: string
  hide?: boolean
  campaign?: boolean
}

export type TopBanner = {
  text?: string
  url?: string
  textColor: string
  backgroundColor: string
  showTrustpilotStars?: boolean
}

export type FooterData = {
  data: {
    informationNavLinks: { label: string; url: string; externalLink: boolean; hide: boolean }[]
    aboutNavLinks: { label: string; url: string; externalLink: boolean; hide: boolean }[]
    companyInformation: { nameOrgNum: string; address: string; copyrightEmail: string }
    socialMediaLinks: SocialMediaLink[]
  }
}

type LinkContextType = {
  getCanonicalLocale: (slug: string, locale: string, defaultLocale: string) => string
  getPathWithoutTrailingSlash: (path: string) => string
  getCanonicalUrl: (slug: string, locale: string, defaultLocale: string, isMissingTranslationToCurrentLocale?: boolean) => string
}

const LinkContextDefaultValues: LinkContextType = {
  getCanonicalLocale: () => '',
  getPathWithoutTrailingSlash: () => '',
  getCanonicalUrl: () => '',
}

const LinkContext = createContext<LinkContextType>(LinkContextDefaultValues)

export function useLink(): LinkContextType {
  return useContext(LinkContext)
}

type PropTypes = {
  children: ReactNode
}

export const LinkProvider: FC<PropTypes> = ({ children }) => {
  const getCanonicalLocale = (slug: string, locale: string, defaultLocale: string): string => {
    // if we're at the blog, but the chosen locale is not yet supported, the canonical should be the default locale
    if (isBlog(slug) && !isLocaleSupportedByBlog(locale)) {
      return defaultLocale
    }

    return locale
  }

  const isBlog = (slug: string): boolean => {
    return slug === 'stories' || slug.indexOf('stories/') === 0 || slug.indexOf('/stories') === 0
  }

  const isLocaleSupportedByBlog = (locale: string): boolean => {
    const available_languages: string[] = ['en-gb', 'en-us', 'en-au', 'en-ca', 'nl-nl', 'de-de', 'sv-se', 'it-it', 'fr-fr', 'es-es']
    return available_languages?.includes(locale)
  }

  const avoidAdsUrlsToBeIndexed = (path: string): string => {
    if (path.indexOf('&af_adset_id') > -1 || path.indexOf('?af_adset_id') > -1) {
      path = path.substring(0, path.indexOf('?'))
    }
    return path
  }

  const getPathWithoutTrailingSlash = (path: string): string => {
    let pathWithoutTrailingSlash = path.slice(-1) === '/' ? path.slice(0, -1) : path
    pathWithoutTrailingSlash = avoidAdsUrlsToBeIndexed(pathWithoutTrailingSlash)
    return pathWithoutTrailingSlash
  }

  const getCanonicalUrl = (slug: string, locale: string, defaultLocale: string, isMissingTranslationToCurrentLocale?: boolean): string => {
    const pathWithoutTrailingSlash = getPathWithoutTrailingSlash(slug)
    const indexOfQuestionMark = pathWithoutTrailingSlash.indexOf('?')
    const pathWithoutTrailingSlasAndParameters =
      indexOfQuestionMark > -1 ? pathWithoutTrailingSlash.substring(0, indexOfQuestionMark) : pathWithoutTrailingSlash

    if (isMissingTranslationToCurrentLocale) {
      // if the content isn't translated to the current locale, we should use the default locale in the canonical
      const canonicalUrl = `https://onceupon.photo${pathWithoutTrailingSlasAndParameters}`
      return canonicalUrl
    } else {
      // if the content is translated to the current locale, we should use the current locale in the canonical
      const localesToDefaultInCanonical: string[] = ['en-gb', 'en-au', 'en-us', 'en-ca']
      const canonicalLocale = getCanonicalLocale(slug, locale, defaultLocale)
      const canonicalLocaleAsPath = localesToDefaultInCanonical.indexOf(canonicalLocale) > -1 ? '' : `/${canonicalLocale}`

      const canonicalUrl = `https://onceupon.photo${canonicalLocaleAsPath}${pathWithoutTrailingSlasAndParameters}`
      return canonicalUrl
    }
  }

  const value = {
    getCanonicalLocale,
    getPathWithoutTrailingSlash,
    getCanonicalUrl,
  }

  return (
    <>
      <LinkContext.Provider value={value}>{children}</LinkContext.Provider>
    </>
  )
}
