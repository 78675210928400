import axios from 'axios'
import log from 'utils/logger'
import cache from 'memory-cache'
import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, GetStaticPropsResult } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { FC } from 'react'
import { AppStoreData } from 'types/AppStoreData'
import { gql } from '@apollo/client'
import Layout from '../components/Layout'
import Page from '../components/Page'
import { FooterData, NavigationHeader, PageAlternateLink, TopBanner } from '../context/LinkContext'
import ChristmasDeliveryDate from '../types/ChristmasDeliveryDates'
import DeliveryInfo from '../types/DeliveryInfo'
import PaymentOption from '../types/PaymentOption'
import JobylonJob from '../types/JobylonJob'
import client from '../utils/api-client'
import { Product, getStoreData } from 'utils/get-store-data'
import { builder } from '@builder.io/react'
import { BuilderContent } from '@builder.io/sdk'
import { ExtendendBuilderContent } from 'types/BuilderTypes'
import {
  CmsPath,
  ModelType,
  PathParam,
  aggregateBuilderPaths,
  getAllBlogArticles,
  getAllBuilderPages,
  getFooterData,
  getNavHeaders,
  getOriginalContentId,
  getPageAlternatesForBuilder,
  getSlugForOriginalVariant,
  getTopBanners,
} from 'utils/cms-helper'

type PropTypes = {
  builderContent?: BuilderContent
  pageAlternatesForBuilder?: PageAlternateLink[]
  preview?: boolean
  jobs?: JobylonJob[]
  deliveryInfo?: DeliveryInfo
  paymentOptions?: PaymentOption[]
  appStoreData?: AppStoreData
  christmasDeliveryDates?: ChristmasDeliveryDate
  storeData?: Product[]
  contentType: ModelType
  navHeaders?: NavigationHeader[]
  topBanners?: TopBanner[]
  footerData?: FooterData
}

const Slug: FC<PropTypes> = ({ appStoreData, builderContent, pageAlternatesForBuilder, contentType, navHeaders, topBanners, footerData }) => {
  const pageAlternates: PageAlternateLink[] = pageAlternatesForBuilder

  return (
    <Layout pageAlternates={pageAlternates} navHeaders={navHeaders} topBanners={topBanners} footerData={footerData}>
      <Page
        builderContent={builderContent}
        appStoreData={appStoreData}
        pageAlternatesForBuilder={pageAlternatesForBuilder}
        contentType={contentType}
      />
    </Layout>
  )
}
const getAppStoreData = async (): Promise<AppStoreData> => {
  const url = 'http://itunes.apple.com/lookup?id=1187208815&country=se'
  const cachedResponse = cache.get(url)
  let data
  if (cachedResponse) {
    data = cachedResponse
  } else {
    const response = await axios.get('http://itunes.apple.com/lookup?id=1187208815&country=se')
    data = response.data.results[0]
    cacheData(url, data)
  }
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    name: 'Once Upon | Photo Books',
    operatingSystem: 'iOS',
    applicationCategory: 'LifestyleApplication',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: (Math.round(data.averageUserRating * 10) / 10).toString(),
      ratingCount: data.userRatingCount.toString(),
    },
    offers: {
      '@type': 'Offer',
      price: '0',
    },
  }
  return structuredData
}

const getJobList = async (slug: string): Promise<JobylonJob[]> => {
  if (slug !== '/about-us/work-with-us' && slug !== 'WIP_work-with-us') return null
  const response = await axios.get('https://feed.jobylon.com/feeds/1f7135dcb7d94bf592a6281c8201c54b/?format=json')
  return response.data
}

const cacheData = (key: string, data: unknown): void => {
  const hours = 24
  const hoursInMs = hours * 1000 * 60 * 60
  cache.put(key, data, hoursInMs)
}

const getDeliveryInfo = async (language: string, region: string, slug: string): Promise<DeliveryInfo> => {
  if (slug !== 'delivery-payment') return null
  // if Canada get delivery options for US
  const lang = region === 'CA' ? 'en_US' : `${language}_${region}`
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: gql`
      query {
        listDeliveryInfo(country_code: ${region}, lang: ${lang}, platform: WEB) {
          checkout_shipment_info
          countries {            
            country_code
            country_name
            delivery_copy
            max_delivery_time
            min_delivery_time
          }
          home_story_shipping_body
        }
      }
    `,
  })
  return data.listDeliveryInfo as DeliveryInfo
}
export const getProductInfo = async (locale: string): Promise<Product[]> => {
  return await getStoreData(locale)
}
export const getPaymentOptions = async (language: string, region: string): Promise<PaymentOption[]> => {
  // if Canada get payment options for US
  const lang = region === 'CA' ? 'en_US' : `${language}_${region}`
  const { data } = await client.query({
    fetchPolicy: 'cache-first',
    query: gql`
        query {
          getPaymentOption(lang: ${lang}, locale: "${region}", userId: "1") {
            description
            image {
              height
              url
              width
            }
            name
            type
          }
        }
      `,
  })
  return data.getPaymentOption as PaymentOption[]
}
const getChristmasDates = async (language: string, region: string, slug: string): Promise<ChristmasDeliveryDate> => {
  if (slug !== 'christmas-delivery') return null
  // if Canada get christmas delivery dates for US
  const lang = region === 'CA' ? 'en_US' : `${language}_${region}`
  try {
    const { data } = await client.query({
      query: gql`
        query {
          listChristmasDeliveryInfo(lang: ${lang}) {
            home_banner_christmas_orders
            home_story_xmasdelivery_body
            msg_inline_christmas
            countries {
              country
              country_code
              last_order_date,
            }
          }
        }
      `,
    })
    return data.listChristmasDeliveryInfo as ChristmasDeliveryDate
  } catch (err) {
    return null
  }
}

const previewEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

// Default cache in production. No cache in development
const builderCache = prodEnvironment ? {} : { cacheSeconds: 0, staleCacheSeconds: 0 }
const builderLimit = 50
export const getStaticProps: GetStaticProps = async (context: GetStaticPropsContext): Promise<GetStaticPropsResult<PropTypes>> => {
  let dynamicSlug = context.params.slug || ['home']
  const language = context.locale.substring(0, 2)
  const region = context.locale.substring(3).toUpperCase()
  let urlPath = ''
  if (Array.isArray(dynamicSlug)) {
    urlPath = '/' + dynamicSlug.join('/')
    dynamicSlug = dynamicSlug.join('/')
  }

  let builderContent: ExtendendBuilderContent = null
  let pageAlternatesForBuilder: PageAlternateLink[] = null
  let slugForOriginalVariantOfCurrentPage: string = null
  let contentType: 'page' | 'generic-page' = 'page'
  const localeLowercase = `${language}-${region.toLowerCase()}`
  const locale = region === 'CA' ? `${language}-${region.toUpperCase()}` : localeLowercase
  // look for the slug/page in Builder.io
  try {
    builderContent = await builder
      .get('page', {
        ...builderCache,
        userAttributes: { urlPath, locale },
        options: { locale: localeLowercase, includeUnpublished: !prodEnvironment && !previewEnvironment },
      })
      .toPromise()
    if (!builderContent) {
      contentType = 'generic-page'
      builderContent = await builder
        .get('generic-page', {
          ...builderCache,
          userAttributes: { urlPath, locale },
          options: { includeRefs: true, locale: localeLowercase, includeUnpublished: !prodEnvironment && !previewEnvironment },
        })
        .toPromise()
    }

    if (builderContent) {
      const originalContentId = getOriginalContentId(builderContent)
      const builderPageInAllLocales = (await builder.getAll(contentType, {
        ...builderCache,
        options: { includeRefs: true, noTargeting: true, includeUnpublished: !prodEnvironment && !previewEnvironment },
        query: {
          $or: [
            {
              'data.reference.id': originalContentId,
            },
            {
              id: originalContentId,
            },
          ],
        },
      })) as ExtendendBuilderContent[]

      pageAlternatesForBuilder = getPageAlternatesForBuilder({ content: builderPageInAllLocales, originalContentId })
      slugForOriginalVariantOfCurrentPage = getSlugForOriginalVariant({ content: builderPageInAllLocales, originalContentId })
    }
  } catch (err) {
    log.error('BUILDER ERROR', err)
  }

  let jobData = null
  try {
    if (builderContent) {
      jobData = await getJobList(slugForOriginalVariantOfCurrentPage)
    }
  } catch (err) {}

  const navHeaders = await getNavHeaders(builderCache, locale)
  const topBanners = await getTopBanners(builderCache, locale)
  const footerData = await getFooterData(builderCache, locale)

  if (!builderContent) {
    return {
      props: {
        contentType: contentType,
      },
      notFound: true,
      revalidate: prodEnvironment ? 600 : 600,
    }
  }

  return {
    props: {
      builderContent: builderContent || null,
      pageAlternatesForBuilder: pageAlternatesForBuilder,
      preview: context.preview || false,
      jobs: jobData,
      appStoreData: await getAppStoreData(),
      deliveryInfo: slugForOriginalVariantOfCurrentPage?.includes('delivery-payment')
        ? await getDeliveryInfo(language, region, 'delivery-payment')
        : null,
      paymentOptions: await getPaymentOptions(language, region),
      christmasDeliveryDates: slugForOriginalVariantOfCurrentPage?.includes('christmas-delivery')
        ? await getChristmasDates(language, region, 'christmas-delivery')
        : null,
      storeData: await getProductInfo(context.locale),
      ...(await serverSideTranslations(`${language}-${region}`, ['common'])),
      contentType: contentType,
      navHeaders: navHeaders ?? null,
      topBanners: topBanners ?? null,
      footerData: footerData ?? null,
    },
    revalidate: prodEnvironment ? 600 : 600,
  }
}
export const getPathsFromBuilder = async (): Promise<CmsPath> => {
  const fields = 'data.url,query'
  const builderPages_page = await getAllBuilderPages({
    limit: builderLimit,
    fields,
    builderCache,
    contentType: 'page',
    includeUnpublished: !prodEnvironment && !previewEnvironment,
  })

  const builderPages_genericPage = await getAllBuilderPages({
    limit: builderLimit,
    fields,
    builderCache,
    contentType: 'generic-page',
    includeUnpublished: !prodEnvironment && !previewEnvironment,
  })

  const builderPages_blogArticle = await getAllBlogArticles(builderCache, !prodEnvironment && !previewEnvironment)
  const builderPages = [...builderPages_page, ...builderPages_genericPage, ...builderPages_blogArticle]

  const reducedPages = builderPages.reduce(aggregateBuilderPaths, [] as PathParam[])
  const afterReducedPages = reducedPages?.map((obj) => {
    const urls = obj.url.slice(1).split('/')
    return { params: { slug: [...urls] }, locale: obj.locale?.toLowerCase() ?? 'en-gb' }
  })

  return afterReducedPages
}
export const getStaticPaths: GetStaticPaths = async () => {
  if (!prodEnvironment) {
    return {
      paths: [],
      fallback: 'blocking',
    }
  }

  const builderPaths = await getPathsFromBuilder()
  const allPaths = [...builderPaths]
  return {
    paths: allPaths,
    fallback: 'blocking',
  }
}
export default Slug
