// import Link from 'next-translate-routes/link'
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { getCampaignParameters } from 'utils/get-campaign-parameters'
import { CSSProperties, FC, ReactNode } from 'react'

type PropTypes = {
  children: ReactNode
  anchor?: string
  style?: CSSProperties
  id?: string
}

const InternalLink: FC<LinkProps & PropTypes> = (props) => {
  const { href, children, anchor, id, ...rest } = props
  const router = useRouter()
  const campaignParameters = getCampaignParameters(router.query)
  const campaignParametersAsQueryString = new URLSearchParams(campaignParameters).toString()
  let url = href
  const hasParametersAlreadyBeenAppended = url?.toString().indexOf(campaignParametersAsQueryString) > -1

  if (campaignParameters && !hasParametersAlreadyBeenAppended) {
    url = `${url}?${campaignParametersAsQueryString}`
  }
  if (anchor) {
    url = `${url}#${anchor}`
  }

  // Special hack relating to the Kundo implementation.
  // <Link> is for internal links, and the FAQ is internal but
  // loads a Script externally, which requires ut to use <a> instead.
  const isFAQ = (url: string): boolean => {
    const faqUrls = [
      'help/faq',
      'help/faq',
      'ayuda/preguntas-frecuentes',
      'hjelp/vanliga-sporsmal',
      'help/faq',
      'aiuto/faq',
      'aide/faq',
      'hjalp/ofte-stillede-sporgsmal',
      'pomoc/faq',
      'hilfe/faq',
      'hjalp/faq',
    ]

    return faqUrls.some((substring) => url.includes(substring))
  }

  if (isFAQ(url.toString())) {
    return (
      <a href={url.toString()} id={id} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <Link href={url} id={id} {...rest}>
      {children}
    </Link>
  )
}

export default InternalLink
