import { FC } from 'react'
import Link from 'next/link'
import { Box, Carousel, Text } from 'atomic'
import { TopBanner } from 'context/LinkContext'
import TrustpilotStars from 'components/trustpilot/TrustpilotStars'

type PropTypes = {
  topBanners: TopBanner[]
}

const TopBanners: FC<PropTypes> = ({ topBanners }) => {
  if (!topBanners || topBanners.length === 0) return null

  return (
    <Carousel
      paginatorPosition="hidden"
      autoChangeInterval={topBanners.length > 1 ? 5000 : undefined}
      items={topBanners?.map((topBanner, index) => {
        return (
          <Box
            key={index}
            display="flex"
            style={{ backgroundColor: topBanner.backgroundColor }}
            paddingLeft="s"
            paddingRight="s"
            paddingTop="xxs"
            paddingBottom="xxs"
            justifyContent="center"
            gap="m"
            id="top-banner"
          >
            {topBanner.url && (
              <Link href={topBanner.url}>
                <Text margin="none" fontSize="s" fontWeight="semibold" style={{ color: topBanner.textColor }}>
                  {topBanner.text}
                </Text>
              </Link>
            )}
            {!topBanner.url && (
              <Text margin="none" fontSize="s" fontWeight="semibold" style={{ color: topBanner.textColor }}>
                {topBanner.text}
              </Text>
            )}
            {topBanner.showTrustpilotStars && (
              <Box style={{ alignSelf: 'flex-end', height: '20px' }}>
                <TrustpilotStars width={84} height={20} />
              </Box>
            )}
          </Box>
        )
      })}
    />
  )
}

export default TopBanners
