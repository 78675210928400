import { FooterData, NavigationHeader, PageAlternateLink, TopBanner } from 'context/LinkContext'
import { ExtendendBuilderContent, LanguageVariant } from 'types/BuilderTypes'
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher'
import builder from '@builder.io/react'
import { BuilderContent } from '@builder.io/sdk'

const prodEnvironment = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
const allSupportedLocales = ['de-de', 'da-dk', 'en-gb', 'en-au', 'en-us', 'en-ca', 'es-es', 'fr-fr', 'it-it', 'nl-nl', 'nb-no', 'sv-se']

type LanguageVariantProps = {
  content: ExtendendBuilderContent[]
  originalContentId: string
  subPath?: string
  locale?: string
}
type ConvertProps = {
  variants: LanguageVariant[]
  host: string
}

export type PathParam = {
  url: string
  locale?: string
}
export type ModelType = 'page' | 'generic-page' | 'blog-article'

type BuilderRequestProps = {
  limit?: number
  offset: number
  fields?: string
  contentType: ModelType
  includeUnpublished: boolean
  builderCache?: { cacheSeconds?: number; staleCacheSeconds?: number }
}
type BuilderGetAllProps = Omit<BuilderRequestProps, 'offset'>

export type PathObj = { params: Params; locale?: string; uid?: string }
export type CmsPath = Array<string | PathObj>
export type IgnoredPath = {
  path: string
  ignoreChildren?: boolean
}

export function isParentPage(content: ExtendendBuilderContent): boolean {
  return content?.data?.reference?.id === undefined
}

export function getOriginalContentId(content: ExtendendBuilderContent): string {
  const isParent = isParentPage(content)
  const originalContentId = isParent ? content.id : content?.data?.reference?.id
  return originalContentId
}

export function getLanguageVariantsBlog({ content, originalContentId, subPath = '' }: LanguageVariantProps): LanguageVariant[] {
  const langFilter = (c: ExtendendBuilderContent): boolean => c.data?.reference?.id === originalContentId || c.id === originalContentId

  const blogArticleInAllLanguages = content.filter(langFilter)
  const languageVariants = blogArticleInAllLanguages.map((c) => {
    const isDefault = c.id === originalContentId
    const path = c.data.slug
    const locale = c.query.filter((q) => q.property === 'locale')[0].value[0]

    return {
      isDefault,
      slug: `/${subPath}${path}`,
      locale,
    }
  })

  const blogArticleInEnGb = blogArticleInAllLanguages.find((c) => {
    const locale = c.query.filter((q) => q.property === 'locale')[0].value[0]?.toLowerCase()
    if (locale === 'en-gb') {
      return c
    }
  })

  const missingLocales = allSupportedLocales.filter((locale) => {
    return !languageVariants.some((c) => c.locale === locale)
  })

  // if the blog article is missing in a language that's supported on the website, we need to add it,
  // as all blog articles will be shown for all languages on the overview and will therefore need a url in the sitemap
  const missingVariants = missingLocales.map((locale) => {
    const path = blogArticleInEnGb.data.slug

    return {
      isDefault: false,
      slug: `/${subPath}${path}`,
      locale,
    }
  })

  const allVariants = [...languageVariants, ...missingVariants]
  return allVariants
}

export function getPageAlternatesForBuilder({ content, originalContentId }: LanguageVariantProps): PageAlternateLink[] {
  const variants = getLanguageVariantsPage({ content, originalContentId })
  const pageAlternates = convertVariantsToHrefLang({ variants, host: 'https://onceupon.photo' })
  return pageAlternates
}

export function getSlugForOriginalVariant({ content, originalContentId }: LanguageVariantProps): string {
  const originalVariant = content.find((c) => c.id === originalContentId)
  if (originalVariant && originalVariant !== undefined) {
    const slug = originalVariant.query.filter((q) => q.property === 'urlPath')[0].value as string
    return slug
  }

  return null
}

export function getLanguageVariantsPage({ content, originalContentId }: LanguageVariantProps): LanguageVariant[] {
  // const langFilter = (c: ExtendendBuilderContent): boolean => c.meta?.originalContentId === originalContentId || c.id === originalContentId
  const langFilter = (c: ExtendendBuilderContent): boolean => c.data?.reference?.id === originalContentId || c.id === originalContentId
  return content.filter(langFilter).map((c) => {
    const isDefault = c.id === originalContentId
    const path = c.query.filter((q) => q.property === 'urlPath')[0].value as string
    const locale = c.query.filter((q) => q.property === 'locale')[0].value[0]
    return {
      isDefault,
      slug: path,
      locale,
    }
  })
}

export function convertVariantsToHrefLang(props: ConvertProps): PageAlternateLink[] {
  const { variants, host } = props
  const hreflangs: PageAlternateLink[] = []
  variants.forEach((variant) => {
    const slug = variant.slug === '/home' ? '' : variant.slug

    if (variant.isDefault) {
      hreflangs.push({ hreflang: variant.locale, href: `${host}${slug}` })
      hreflangs.push({ hreflang: 'x-default', href: `${host}${slug}` })

      // add hreflang for language code only, i.e. <link rel="alternate" hreflang="en" href="https://onceupon.photo">
      const languageCode = variant.locale.length > 2 ? variant.locale.slice(0, 2) : variant.locale
      hreflangs.push({ hreflang: languageCode, href: `${host}${slug}` })
    } else {
      hreflangs.push({ hreflang: variant.locale, href: `${host}/${variant.locale}${slug}` })
    }
  })

  return hreflangs
}

const getResults = async ({
  limit,
  offset,
  fields,
  builderCache,
  contentType,
  includeUnpublished,
}: BuilderRequestProps): Promise<ExtendendBuilderContent[]> => {
  return (await builder.getAll(contentType, {
    ...builderCache,
    limit,
    options: { noTargeting: true, offset, includeUnpublished },
    fields,
  })) as ExtendendBuilderContent[]
}

export const aggregateBuilderPaths = (acc: PathParam[], curr: ExtendendBuilderContent): PathParam[] => {
  // let selectedLocale = 'en-gb'
  const url = curr.data.url ?? curr.data.slug // url for page and generic-page, slug for blog-article
  const locale = curr.query.filter((q) => q.property === 'locale')
  if (locale.length > 0) {
    const locales = (locale[0].value as string[]) ?? []
    const availableLocales: PathParam[] = locales.map((v) => ({ url, locale: v }))
    return [...acc, ...availableLocales]
  }
  return acc

  // Use this if we add support for multiple urls/page
  // return typeof url === 'string' ? [...acc, { url, locale: selectedLocale }] : [...acc, ...url.map((u) => ({ url: u, locale: selectedLocale }))]
}

export const getAllBuilderPages = async ({
  limit,
  fields,
  builderCache,
  contentType,
  includeUnpublished,
}: BuilderGetAllProps): Promise<ExtendendBuilderContent[]> => {
  let offset = 0
  const pages = await getResults({ limit, offset, fields, builderCache, contentType, includeUnpublished })
  let resultLength = pages.length
  while (pages.length === limit + offset) {
    offset += resultLength
    const nextPages = await getResults({ limit, offset, fields, builderCache, contentType, includeUnpublished })
    resultLength = nextPages.length
    pages.push(...nextPages)
  }
  return pages
}

// We don't translate the blog to all supported languages,
// so we need to show the blog in the default language if the user selects a non-supported language.
export const getLocaleToShowBlogIn = (locale: string): string => {
  let localeToShowBlogIn = locale
  const supportedLocales = ['en-gb', 'sv-se', 'de-de', 'nl-nl', 'fr-fr']
  if (supportedLocales.indexOf(locale) === -1) {
    localeToShowBlogIn = 'en-gb'
  }

  return localeToShowBlogIn
}

export const getNavHeaders = async (builderCache, locale: string): Promise<NavigationHeader[]> => {
  if (locale === 'en-ca') {
    locale = 'en-CA'
  }
  const navigation = await builder.get('navigation', {
    ...builderCache,
    options: { includeRefs: false, locale: locale, includeUnpublished: !prodEnvironment },
    userAttributes: {
      locale: locale,
    },
  })

  const navHeaders: NavigationHeader[] = navigation?.data?.navHeaders
    ?.map((header) => ({
      ...header.navHeader,
      navItems: header.navHeader.navItems?.map((item) => item.navItem)?.filter((navHeader) => !navHeader.hide),
    }))
    ?.filter((navHeader) => !navHeader.hide)

  return navHeaders
}

export const getTopBanners = async (builderCache, locale: string): Promise<TopBanner[]> => {
  if (locale === 'en-ca') {
    locale = 'en-CA'
  }
  const builderContent = (await builder.getAll('top-banner', {
    ...builderCache,
    options: { includeRefs: true, locale: locale, includeUnpublished: !prodEnvironment },
    userAttributes: {
      locale: locale,
    },
    query: {
      data: {
        hide: false,
      },
    },
  })) as BuilderContent[]

  const topBanners: TopBanner[] = builderContent.map((topBanner) => {
    return {
      text: topBanner.data?.text ?? '',
      url: topBanner.data?.url ?? '',
      backgroundColor: topBanner.data?.backgroundColor?.value.data.color,
      textColor: topBanner.data?.textColor?.value.data.color,
      showTrustpilotStars: topBanner.data?.showTrustpilotStars ?? false,
    }
  })

  return topBanners
}

export const getFooterData = async (builderCache, locale: string): Promise<FooterData> => {
  if (locale === 'en-ca') {
    locale = 'en-CA'
  }
  const footerData: FooterData = (await builder.get('footer-data', {
    ...builderCache,
    options: { includeRefs: false, locale: locale, includeUnpublished: !prodEnvironment },
    userAttributes: {
      locale: locale,
    },
  })) as FooterData

  footerData.data.informationNavLinks = footerData?.data?.informationNavLinks?.filter((navLink) => !navLink.hide)
  footerData.data.aboutNavLinks = footerData?.data?.aboutNavLinks?.filter((navLink) => !navLink.hide)

  return footerData
}

export const getAllBlogArticles = async (builderCache, includeUnpublished: boolean): Promise<ExtendendBuilderContent[]> => {
  const blogArticles = await getAllBuilderPages({
    limit: 50,
    fields: 'id,meta.originalContentId,data.noindex,data.slug,data.reference.id,query',
    builderCache,
    contentType: 'blog-article',
    includeUnpublished,
  })

  // find all blog articles in en-gb, as they are the reference for all other languages
  const blogArticlesInEnGb = blogArticles.filter((c) => {
    const locale = c.query.filter((q) => q.property === 'locale')[0].value[0]?.toLowerCase()
    if (locale === 'en-gb') {
      return c
    }
  })

  blogArticlesInEnGb.forEach((article) => {
    // we know that blogArticlesInEnGb is the default language, so we can use that to find the reference/original content id
    const originalContentId = article.id
    const langFilter = (c: ExtendendBuilderContent): boolean => c.data?.reference?.id === originalContentId || c.id === originalContentId

    // find the article we're looping through, in all languages that it exists in Builder (including the default language),
    // using the reference/original content id
    const blogArticleInAllLanguages = blogArticles.filter(langFilter)

    // if the blog article is missing in a language that's supported on the website, we need to add it,
    // as all blog articles will be shown for all languages on the overview and will therefore need a url in the sitemap
    allSupportedLocales.forEach((locale) => {
      if (!blogArticleInAllLanguages.some((c) => c.query.filter((q) => q.property === 'locale')[0].value[0]?.toLowerCase() === locale)) {
        blogArticles.push({
          ...article,
          query: [{ property: 'locale', operator: 'is', value: [locale] }],
        })
      }
    })
  })

  return blogArticles
}

export const extractSitemapUrls = (builderPages: ExtendendBuilderContent[]): string[] => {
  const all_urls: string[] = []
  builderPages.forEach((link) => {
    // don't include pages with noindex
    if (link.data.noindex === true) {
      return
    }

    // page and generic-page have url, blog-article has slug
    let slug = link.data.url ?? '/stories/' + link.data.slug
    if (slug === '/home') {
      slug = ''
    }

    // find locale, and don't include pages without locale
    const localeValue = link.query.filter((q) => q.property === 'locale')[0].value
    const locale = localeValue !== null ? localeValue[0] : undefined
    if (locale) {
      const slugWithoutFirstSlash = slug.startsWith('/') ? slug.slice(1) : slug
      const finalSlug = locale === 'en-gb' ? slugWithoutFirstSlash : locale.toLowerCase() + slug
      all_urls.push(finalSlug)
    }
  })

  return all_urls
}
