import { useState, useEffect, FC, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styles from './CookieBanner.module.scss'
import { Box, Button, Container, Heading, Text } from 'atomic'
import SettingsIcon from 'components/icons/Settings'
import Switch from 'components/switchbutton/Switch'
import { useCookies } from 'react-cookie'
import CookieIcon from 'components/icons/CookieIcon'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import Link from 'next/link'

export type ConsentState = {
  necessary: boolean
  analytical: boolean
  marketing: boolean
}

type CookieMode = 'necessary' | 'all' | 'custom'

export const consentMode = { necessary: 'OU_necessary', analytical: 'OU_analytical', marketing: 'OU_marketing' }

export const useOuCookies = () => {
  const [cookies, setCookies] = useCookies([consentMode.necessary, consentMode.analytical, consentMode.marketing, 'NEXT_LOCALE'])
  const { NEXT_LOCALE } = cookies

  return {
    consentNecessary: true,
    consentAnalytical: cookies[consentMode.analytical] ?? false,
    consentMarketing: cookies[consentMode.marketing] ?? false,
    NEXT_LOCALE,
    setCookies,
  }
}

const CookieConsentBanner = () => {
  const [cookies, setCookie] = useCookies([consentMode.necessary, consentMode.analytical, consentMode.marketing])
  const cookieNecessary = cookies[consentMode.necessary]
  const cookieAnalytical = cookies[consentMode.analytical]
  const cookieMarketing = cookies[consentMode.marketing]

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const showCookieBanner = cookieAnalytical === undefined && cookieMarketing === undefined && cookieNecessary === undefined

  const ref = useRef<HTMLDivElement>()

  const initialState: ConsentState = {
    necessary: cookieNecessary ?? false,
    analytical: cookieAnalytical ?? false,
    marketing: cookieMarketing ?? false,
  }
  const [cookieState, setCookieState] = useState<ConsentState>(initialState)

  const [customizeVisible, setCustomizeVisible] = useState(false)

  useEffect(() => {
    setCookieState({
      necessary: cookieNecessary ?? false,
      analytical: cookieAnalytical ?? false,
      marketing: cookieMarketing ?? false,
    })
    if (typeof window?.gtag !== 'undefined') {
      gtag('consent', 'update', {
        ad_storage: cookieMarketing ? 'granted' : 'denied',
        ad_user_data: cookieMarketing ? 'granted' : 'denied',
        ad_personalization: cookieMarketing ? 'granted' : 'denied',
        analytics_storage: cookieAnalytical ? 'granted' : 'denied',
        personalization_storage: cookieMarketing ? 'granted' : 'denied',
        functionality_storage: cookieNecessary ? 'granted' : 'denied',
        security_storage: cookieNecessary ? 'granted' : 'denied',
      })
    }
  }, [cookieAnalytical, cookieMarketing, cookieNecessary])

  const handleCookieChange = (cookieType: keyof ConsentState) => {
    setCookieState({ ...cookieState, [cookieType]: !cookieState[cookieType] })
  }

  const handleAccept = (mode: CookieMode) => {
    let selectedCookieState: ConsentState

    switch (mode) {
      case 'necessary':
        selectedCookieState = {
          necessary: true,
          analytical: false,
          marketing: false,
        }
        break
      case 'all':
        selectedCookieState = {
          necessary: true,
          analytical: true,
          marketing: true,
        }
        break
      case 'custom':
        selectedCookieState = cookieState
    }

    setCookieState(selectedCookieState)

    setCookie(consentMode.necessary, true)
    setCookie(consentMode.analytical, selectedCookieState.analytical)
    setCookie(consentMode.marketing, selectedCookieState.marketing)
  }

  return isClient && showCookieBanner ? (
    <div ref={ref} className={styles.container}>
      <Container display="flex" alignItems="flex-start" flexDirection={{ mobile: 'column', tablet: 'row' }} gap={{ mobile: 'xs', tablet: 'l' }}>
        <div className={styles.cookieIcon}>
          <CookieIcon />
        </div>

        <div>
          <DefaultSettings toggleCustomize={() => setCustomizeVisible(!customizeVisible)} handleAccept={handleAccept} />
          <AnimatePresence>
            {customizeVisible && (
              <motion.div initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }}>
                <CustomSettings
                  cookieState={cookieState}
                  handleCookieChange={handleCookieChange}
                  handleAccept={handleAccept}
                  minimizeCustomize={() => setCustomizeVisible(false)}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Container>
    </div>
  ) : null
}

type CustomSettingsProps = {
  cookieState: ConsentState
  handleCookieChange: (cookieType: keyof ConsentState) => void
  handleAccept: (mode: CookieMode) => void
  minimizeCustomize: () => void
}
const CustomSettings: FC<CustomSettingsProps> = ({ cookieState, handleCookieChange, handleAccept, minimizeCustomize }) => {
  const { t } = useSafeTranslation()

  return (
    <Box alignItems={{ mobile: 'stretch', tablet: 'flex-end' }} display="flex" flexDirection="column">
      <CookieSettingRow
        title={t('index_cookies_customize_necessary_title')}
        description={t('index_cookies_customize_necessary_body')}
        isMandatory
        checked={cookieState.necessary}
        onChange={() => handleCookieChange('necessary')}
      />

      <CookieSettingRow
        title={t('index_cookies_analytical_title')}
        description={t('index_cookies_analytical_body')}
        checked={cookieState.analytical}
        onChange={() => handleCookieChange('analytical')}
      />
      <CookieSettingRow
        title={t('index_cookies_marketing_title')}
        description={t('index_cookies_marketing_body')}
        checked={cookieState.marketing}
        showBottomBorder={false}
        onChange={() => handleCookieChange('marketing')}
      />
      <Box
        display="flex"
        flexDirection={{ mobile: 'column', tablet: 'row' }}
        justifyContent={'space-between'}
        marginTop="l"
        style={{ width: '100%' }}
      >
        <Box>
          <Link href="/cookies" onClick={minimizeCustomize}>
            <Text fontSize={'s'} fontWeight={'light'} marginBottom={'none'} className={styles.links}>
              {t('cookie_policy_link')}
            </Text>
          </Link>
        </Box>
        <Box>
          <Button variant="cta" onClick={() => handleAccept('custom')}>
            {t('index_cookies_button_save')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

type DefaultSettingsProps = {
  toggleCustomize: () => void
  handleAccept: (mode: CookieMode) => void
}
const DefaultSettings: FC<DefaultSettingsProps> = ({ toggleCustomize, handleAccept }) => {
  const { t } = useSafeTranslation()
  return (
    <Box position="relative" display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} gap="s" className={styles.defaultCookieSettings}>
      <Box>
        <Heading as="h3" marginBottom="xxs" fontSize="base">
          {t('index_cookies_collapsed_title')}
        </Heading>
        <Text fontSize="s">{t('index_cookies_collapsed_body')}</Text>
      </Box>
      <Box
        display="flex"
        flexDirection={{ mobile: 'column', tablet: 'column-reverse' }}
        alignItems={{ mobile: 'stretch', tablet: 'flex-end' }}
        justifyContent="space-between"
      >
        <button onClick={toggleCustomize} className={styles.cookieSettingsButton}>
          <SettingsIcon />
          <Text as="span" fontWeight={'bold'}>
            {t('index_cookies_collapsed_button_customize')}
          </Text>
        </button>
        <Box display="flex" marginTop={{ mobile: 'xxs', tablet: 'none' }} flexDirection={{ mobile: 'column', tablet: 'row' }} gap="xxs">
          <Button onClick={() => handleAccept('all')}>{t('index_cookies_collapsed_button_all')}</Button>
          <Button variant="secondary" onClick={() => handleAccept('necessary')}>
            {t('index_cookies_collapsed_button_necessary')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

type RowProps = {
  title: string
  description: string
  checked: boolean
  onChange: () => void
  showBottomBorder?: boolean
  isMandatory?: boolean
}
const CookieSettingRow: FC<RowProps> = ({ title, description, checked, onChange, showBottomBorder = true, isMandatory }) => {
  const bottomBorder = showBottomBorder
    ? {
        borderBottom: '1px solid #E5E5E5',
      }
    : null
  return (
    <motion.div
      className={styles.cookieSettingRow}
      style={bottomBorder}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <div>
        <Heading as={'h4'} fontSize={'base'} marginBottom={'xxs'}>
          {title}
        </Heading>
        <Text fontSize={'s'}>{description}</Text>
      </div>
      {!isMandatory ? (
        <div className={styles.cookieSettingRowSwitch}>
          <Switch name={title} defaultChecked={checked} onCheck={onChange} />
        </div>
      ) : null}
    </motion.div>
  )
}

export default CookieConsentBanner
