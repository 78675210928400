import Image from 'next/image'
import { FC } from 'react'
import LocalizedObject from '../../types/LocalizedObject'
import { ouTheme } from 'atomic/styles/theme.css'

const icons: LocalizedObject = {
  'de-de': 'de.svg',
  'da-dk': 'da.svg',
  'en-gb': 'en.svg',
  'en-au': 'en.svg',
  'en-us': 'en.svg',
  'en-ca': 'en.svg',
  'es-es': 'es.svg',
  'fr-fr': 'fr.svg',
  'it-it': 'it.svg',
  'nl-nl': 'nl.svg',
  'nb-no': 'no.svg',
  'sv-se': 'sv.svg',
}

type PropTypes = {
  locale: string
}

const GooglePlay: FC<PropTypes> = ({ locale }) => {
  const localizedIcon = `/icons/googleplay/${icons[locale]}`
  return (
    <Image
      src={localizedIcon}
      width={101}
      height={26}
      style={{ width: 101, height: 26 }}
      sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
      alt="Google Play badge"
    />
  )
}

export default GooglePlay
