import { Link } from 'components/link/Link'
import React, { useState } from 'react'
import { trackOutboundLink } from 'lib/analytics-utils'
import builder from '@builder.io/react'
import { ouTheme, ThemeColors } from 'atomic/styles/theme.css'
import * as css from './CreateCard.css'
import { Box, Button, Heading, Text } from 'atomic'
import { useBackend } from 'context/BackendContext'
import { useCmsOrTranslation, useSafeTranslation } from 'hooks/useSafeTranslation'
import { Trans } from 'next-i18next'
import PlusIcon from 'components/icons/PlusIcon'
import Image from 'next/image'
import RegularArrow from 'components/icons/RegularArrow'

type Card = {
  title: string
  link: string
  color: keyof ThemeColors
  coverImage: string
  hoverImage: string
}

type CreateCardProps = {
  card: Card
  cardType: 'books' | 'prints'
}

const IMAGE_ALT_TEXT_BOOKS = "Picture of Once Upon's photobook covers"
const IMAGE_ALT_TEXT_PRINTS = "Picture of Once Upon's photo prints"

const CreateCard: React.FC<CreateCardProps> = ({ card, cardType }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { storeData } = useBackend()
  const { cmsOrLokalise } = useCmsOrTranslation()
  const { t } = useSafeTranslation()

  const booksBase = storeData?.find((product) => product.key === 'book-20x20cm-soft-cover')
  const printsBase = storeData?.find((product) => product.key === 'card-13x18cm')

  const bookPrice = `${booksBase?.price} ${booksBase?.currency}`
  const printPrice = `${printsBase?.price} ${printsBase?.currency}`

  const title =
    cardType === 'books'
      ? cmsOrLokalise(card.title, 'productpage_hero_photobook_header')
      : cmsOrLokalise(card.title, 'productpage_hero_prints_header')

  return (
    <Link
      href={card.link}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        trackOutboundLink(card.link)
        builder.trackConversion()
      }}
      className={css.container[cardType]}
    >
      <Box position={'relative'} borderRadius={'l'} style={{ width: '100%' }} backgroundColor={card.color}>
        <Box display={'flex'} justifyContent={'space-between'} className={css.cardContainer[cardType]}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={{ mobile: 'xxxs', desktop: 'xxs' }}
            padding={'s-l'}
            backgroundColor={isHovered ? 'white' : 'transparent'}
            className={css.headingContainer[cardType]}
          >
            <Heading
              as={'h3'}
              fontSize={{ mobile: cardType === 'prints' ? 'base' : 'l', desktop: 'l' }}
              fontWeight={'semibold'}
              color={'malm0'}
              marginBottom={'none'}
              display={{ mobile: 'flex', desktop: 'block' }}
              alignItems={{ mobile: 'center', desktop: 'flex-start' }}
              gap={'xxs'}
            >
              {title} <RegularArrow className={css.onlyShowOnMobileAndPrints[cardType]} />
            </Heading>
            <Text fontSize={{ mobile: cardType === 'prints' ? 's' : 'base', desktop: 'base' }} fontWeight={'light'}>
              <Trans
                key={cardType === 'books' ? 'productpage_photobooks_subheading' : 'productpage_prints_subheading'}
                i18nKey={cardType === 'books' ? 'productpage_photobooks_subheading' : 'productpage_prints_subheading'}
                values={{ price: cardType === 'books' ? bookPrice : printPrice }}
                components={{
                  price: (
                    <Text as="span" fontWeight="semibold">
                      price
                    </Text>
                  ),
                }}
              />
            </Text>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'xxs'}
            padding={'s-l'}
            className={css.buttonContainer[cardType]}
          >
            <Button
              variant={'primary'}
              size={'l'}
              gap={'xxs'}
              style={{
                backgroundColor: !isHovered ? '#222' : 'white',
              }}
              onClick={() => {
                trackOutboundLink(card.link)
                builder.trackConversion()
              }}
              className={css.roundButton[cardType]}
            >
              <PlusIcon color={!isHovered ? 'white' : '#222'} />
            </Button>
            {cardType === 'books' && (
              <Button
                variant={'primary'}
                size={'l'}
                gap={'xxs'}
                onClick={() => {
                  trackOutboundLink(card.link)
                  builder.trackConversion()
                }}
                className={css.mobileButton}
              >
                {t('productselection_create_book_header')}
              </Button>
            )}
          </Box>
        </Box>
        <Image
          src={isHovered ? card.hoverImage : card.coverImage}
          fill
          sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
          style={{
            objectFit: isHovered ? 'cover' : 'contain',
            paddingLeft: cardType !== 'prints' && !isHovered ? 20 : 0,
            paddingTop: cardType !== 'prints' && !isHovered ? 50 : 0,
          }}
          alt={cardType === 'books' ? IMAGE_ALT_TEXT_BOOKS : IMAGE_ALT_TEXT_PRINTS}
          className={css.image[cardType]}
        />
        {cardType === 'books' && (
          <Image
            src={card.coverImage}
            fill
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            alt={IMAGE_ALT_TEXT_BOOKS}
            className={css.booksMobileImage}
          />
        )}
        {cardType === 'prints' && (
          <Image
            src={card.coverImage}
            width={147}
            height={126}
            sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 50vw`}
            alt={IMAGE_ALT_TEXT_PRINTS}
            className={css.printsMobileImage}
          />
        )}
      </Box>
    </Link>
  )
}

export default CreateCard
